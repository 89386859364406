import React from 'react';
import { Row, Col, Container } from 'reactstrap';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import { Link } from 'gatsby';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Section from '../../components/Section/Section';
import Headline from '../../components/Headline/Headline';
import image1 from '../../images/Orthopaedie Praxis Dr Schubert.jpg';
import image2 from '../../images/Orthopaede Schubert Praxis Wartebereich.jpg';
import image3 from '../../images/Orthopaede Schubert Praxis Rezeption.jpg';
import image4 from '../../images/Orthopaede Schubert Praxis Rntgen.jpg';
import image5 from '../../images/Orthopaede Schubert Praxis Bereich.jpg';

import imageSchubert from '../../images/Bild14_Dr_Schubert-min.jpg';
import imageKostka from '../../images/Praxis Schubert Kostka.jpg';

import imageTeam1 from '../../images/Nadine M.-min.jpg';
import imageTeam2 from '../../images/Tabea W.-min.jpg';
import imageTeam3 from '../../images/Irem H.-min.jpg';
import imageTeam4 from '../../images/Suemeyye S.-min.jpg';
import imageTeam5 from '../../images/Paula S.-min.jpg';
import imageTeam6 from '../../images/Rosa S.-min.jpg';

const PraxisPage = () => (
  <Layout headerWhite>
    <SEO title="Praxis | Orthopädie Dr. Schubert" description="Die hoch moderne Orthopädie Praxis von Dr. B. Schubert wurde 2020 neu eingerichtet ✓ Hilfsbereites Team ✓ Leichtes Parken ✓ Barrierefrei" />
    <Container>
      <Section>
        <Headline h1 subtitle="Dr. Burkhard Schubert">Praxis</Headline>
        <Row>
          <Col xs={12} lg={12}>
            Barrierefrei - Parkhaus direkt am Platz - klimatisierte Räume
            <br />
            <br />
            Unsere moderne Praxis ist nach neuesten baurechtlichen Vorgaben barrierefrei und behindertengerecht entstanden.
            Der Wartebereich ist mit einer Minibar und  HD- Flachbild TV ausgestattet.
            Wir bieten Ihnen ein kostenloses WLAN Netz.
            <br />
            <br />
            Sie parken direkt unter dem Dr-Ruer-Platz im Parkhaus P2 mit 375 Stellplätzen.
          </Col>
        </Row>
      </Section>
    </Container>
    <Section dark>
      <Row noGutters>
        <Col xs={12} lg={6}>
          <div className="fullWidth-col-image" style={{ background: `white url(${imageSchubert}) center center no-repeat`, backgroundSize: 'cover' }} />
        </Col>

        <Col xs={12} lg={6}>
          <div className="fullWidth-col-content right">
            <Headline light>Dr. med. Burkhard Schubert</Headline>
            <p>
              <ul>
                <li>seit 1999 niedergelassener Orthopäde und bis 2020 in Gemeinschaft mit Dr. Rosenthal in einer  renommierten Praxisklinik in Bochum mit vorwiegend operativen Behandlungsspektrum tätig</li>
                <li>Erfahrung in über 15.000 operativen Behandlungsverläufen</li>
                <li>über 100.000 durchgeführte Gelenkinjektionen und Punktionen</li>
                <li>Kompetenz - Erfahrung - gutes Netzwerk</li>
              </ul>
            </p>
          </div>
        </Col>
      </Row>
    </Section>
    <Section>
      <Container>
        <Row>
          <Col xs={12} md={6}>
            <Headline>
              Zertifizierungen
            </Headline>
            <ul>

              <li>Akupunktur</li>
              <li>Diplom der Österreichischen Gesellschaft für Akupunktur und Auriculotherapie (1997)</li>
              <li>Chirotherapie/ Manuelle Medizin</li>
              <li>Magnetresonanztomographie (MRT) -fachgebunden - Orthopädie</li>
              <li>Fachkunde Strahlenschutz Röntgendiagnostik des gesamten Skelettes</li>
              <li>Stosswellentherapie</li>
              <li>Triggerpunkttherapie</li>
              <li>Kinesiotaping</li>
              <li>Mesotherapie</li>
              <li>Psychosomatische Grundversorgung</li>
            </ul>
          </Col>
          <Col xs={12} md={6}>
            <Headline>Mitgliedschaften</Headline>
            <ul>
              <li>
                <b>BVOU:</b>
                {' '}
                Berufsverband der Fachärzte für Orthopädie und Unfallchirurgie
              </li>
              <li>
                <b>DGMSM:</b>
                {' '}
                Deutsche Gesellschaft für Muskuloskelettale Medizin e.V. (Manuelle Medizin)
              </li>
              <li>
                <b>FACM:</b>
                {' '}
                Forschungsgruppe Akupunktur
              </li>
              <li>
                <b>IGOST:</b>
                {' '}

                Interdisziplinäre Gesellschaft für orthopädische/ unfallchirurgische und allgemeine
                Schmerztherapie e.V.
              </li>
              <li>
                <b>DGM:</b>
                {' '}
                Deutsche Gesellschaft für Mesotherapie e.V .
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Section>
    <AwesomeSlider
      className="fullWidth-slider"
      organicArrows={false}
    >
      <div data-src={image1} />
      <div data-src={image2} />
      <div data-src={image3} />
      <div data-src={image4} />
      <div data-src={image5} />
    </AwesomeSlider>
    <Section>
      <Container>
        <Headline>Unser Team</Headline>
        <Row>
          <Col>
            <img src={imageKostka} style={{ maxHeight: 525, margin: '0 auto 1.45rem', display: 'block' }} alt="Kostka" />
            <div style={{ textAlign: 'center' }}>Dr. Kathrin Kostka</div>
          </Col>
        </Row>

        <Row>
          <Col sm={6} md={4}>
            <img src={imageTeam1} alt="Nadine M." />
            <div style={{ textAlign: 'center' }}>Nadine M.</div>
          </Col>
          <Col sm={6} md={4}>
            <img src={imageTeam2} alt="Tabea W." />
            <div style={{ textAlign: 'center' }}>Tabea W.</div>
          </Col>
          <Col sm={6} md={4}>
            <img src={imageTeam3} alt="Irem H." />
            <div style={{ textAlign: 'center' }}>Irem H.</div>
          </Col>

        </Row>
        <Row>
          <Col sm={6} md={4}>
            <img src={imageTeam4} alt="Sümeyye S." />
            <div style={{ textAlign: 'center' }}>Sümeyye S.</div>
          </Col>
          <Col sm={6} md={4}>
            <img src={imageTeam5} alt="Paula S." />
            <div style={{ textAlign: 'center' }}>Paula S.</div>
          </Col>
          <Col sm={6} md={4}>
            <img src={imageTeam6} alt="Rosa S." />
            <div style={{ textAlign: 'center' }}>Rosa  S.</div>
          </Col>
        </Row>
      </Container>
    </Section>

  </Layout>
);

export default PraxisPage;
